<template>
  <tbody class="table-white table-bordered border-dark">
    <!-- index only -->

    <tr>
      <td class="rotate" rowspan="8">
        <div>{{ tableBody?.body_part }}</div>
      </td>
      <td class="rotate" rowspan="2"><div>DIP</div></td>
      <td>Angle°</td>
      <td>{{ tableBody.dip_flexion_degree }}</td>
      <td>{{ tableBody.dip_extension_degree }}</td>
      <td></td>
      <td style="vertical-align: bottom; text-align: center" rowspan="2">
        {{
          validImp(
            tableBody.dip_flexion_impairment +
              tableBody.dip_extension_impairment
          )
        }}
      </td>
      <td rowspan="6" valign="middle" align="center">
        <img
          class="ueier-img"
          src="@/assets/img/ueier/non_thumb_bone.png"
          alt="upper limbs image"
          style="width: auto; height: auto"
        />
      </td>
      <td rowspan="6" valign="middle" align="center">
        <img
          class="ueier-img"
          src="@/assets/img/ueier/non_thumb_finger.png"
          alt="upper limbs image"
          style="width: auto; height: auto"
        />
      </td>
      <td rowspan="6"></td>
      <td>Abnormal motion [1]</td>
      <td>{{ validImp(abnormalMotion) }}</td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.dip_flexion_impairment }}</td>
      <td>{{ tableBody.dip_extension_impairment }}</td>
      <td></td>
      <td>Amputation [2]</td>
      <td></td>
    </tr>
    <tr>
      <td class="rotate" rowspan="2"><div>PIP</div></td>
      <td>Angle°</td>
      <td>{{ tableBody.pip_flexion_degree }}</td>
      <td>{{ tableBody.pip_extension_degree }}</td>
      <td></td>
      <td style="vertical-align: bottom; text-align: center" rowspan="2">
        {{
          validImp(
            tableBody.pip_flexion_impairment +
              tableBody.pip_extension_impairment
          )
        }}
      </td>
      <td>Sensory loss [3]</td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.pip_flexion_impairment }}</td>
      <td>{{ tableBody.pip_extension_impairment }}</td>
      <td></td>
      <td>Other disorder [4]</td>
      <td></td>
    </tr>
    <tr>
      <td class="rotate" rowspan="2"><div>MP</div></td>
      <td>Angle°</td>
      <td>{{ tableBody.mp_flexion_degree }}</td>
      <td>{{ tableBody.mp_extension_degree }}</td>
      <td></td>
      <td style="vertical-align: bottom; text-align: center" rowspan="2">
        {{
          validImp(
            tableBody.mp_flexion_impairment + tableBody.mp_extension_impairment
          )
        }}
      </td>
      <td rowspan="2">
        Total digit imp %
        <br />
        •Combine 1, 2, 3, 4
      </td>
      <td rowspan="2"></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.mp_flexion_impairment }}</td>
      <td>{{ tableBody.mp_extension_impairment }}</td>
      <td></td>
    </tr>

    <!-- last line -->

    <tr>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
        colspan="6"
      >
        [1]
      </th>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
      >
        [2]
      </th>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
      >
        [3]
      </th>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
      >
        [4]
      </th>
      <th rowspan="2">
        Hand Impairment %
        <br />
        *Convert above
      </th>
      <th rowspan="2">
        {{ handImpairment !== null ? handImpairment + '%' : '' }}
      </th>
    </tr>
    <tr>
      <th colspan="6">
        •Combine digit impairment % MP + PIP + DIP =
        {{ validImp(abnormalMotion) }}
      </th>

      <th>Digit IMP% = ...</th>
      <th>Digit IMP% = ...</th>
      <th>Digit IMP% = ...</th>
    </tr>
    <!-- index only -->
  </tbody>
</template>

<script>
import { percentageCheck } from '@/utils/generics.js'
export default {
  name: 'UeierNonThumbFingerTableBody',

  props: {
    tableBody: {
      type: Object,
      default: null,
    },

    handImpairment: {
      type: Number,
      default: null,
    },

    abnormalMotion: {
      type: [String, Number],
      default: null,
    },
  },

  computed: {},

  methods: {
    validImp(value) {
      return percentageCheck(value)
    },

    // validCm(value) {
    //   if (Number.isInteger(value)) {
    //     return value + 'cm'
    //   } else return '-'
    // },
  },
}
</script>

<style lang="scss" scoped></style>
