<template>
  <div class="container-fluid p-0 my-2">
    <div>
      <p style="margin-bottom: 8.5px">
        <span>
          Name&nbsp;
          <u>
            {{ data?.patient_details?.first_name }}
            {{ data?.patient_details?.middle_name }}
            {{ data?.patient_details?.last_name }}
          </u>
        </span>

        <span>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;</span>

        <span>
          Age&nbsp;
          <u>{{ patientAge }}</u>
        </span>

        <span>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;</span>

        <span>
          Sex&nbsp;
          <RadioCheckboxGroup
            :checked="data?.patient_details?.gender"
            :options="genderOption"
          />
        </span>

        <span>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;</span>

        <span>
          Dominant Hand&nbsp;
          <RadioCheckboxGroup
            :checked="data?.hand_preference"
            :options="handOption"
          />
        </span>

        <span>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;</span>

        <span>
          Date&nbsp;
          <u>{{ formatAusDate(data?.date_time_updated) }}</u>
        </span>
      </p>
    </div>
    <div>
      <p>
        <span>
          Occupation&nbsp;
          <u>{{ data?.occupation }}</u>
        </span>

        <span>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;</span>

        <span>
          Diagnosis&nbsp;
          <u>Refer to the Diagnosis/Diagnoses section</u>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
// HTML above structured this way is to ensure copy paste format follows word document
// Using column or margin or width css html styling doesn't seem to work
//
import RadioCheckboxGroup from '@/components/Report/UEIER/UeierRadioGroup.vue'
import dayjs from 'dayjs'

export default {
  name: 'UeierHeaderPatientDetails',
  components: { RadioCheckboxGroup },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      data: { ...this.patientData },

      genderOption: ['M', 'F'],
      handOption: ['R', 'L'],
    }
  },

  computed: {
    patientAge() {
      if (this.patientData?.patient_details?.date_of_birth) {
        let a = dayjs(new Date())
        let b = dayjs(this.patientData?.patient_details?.date_of_birth)

        return a.diff(b, 'year')
      } else return '-'
    },
  },

  methods: {
    formatAusDate(val) {
      return dayjs(val).format('DD-MM-YYYY')
    },
  },
}
</script>

<style lang="scss" scoped></style>
