<template>
  <!-- summary table -->
  <p class="py-3 fw-bold fs-4">SUMMARY TABLE</p>
  <div v-if="wpiTable" class="w-100 table-responsive mx-auto">
    <table
      ref="wpiTable"
      class="table text-center table-bordered summary-table align-middle"
    >
      <thead style="font-family: times new roman">
        <tr>
          <th class="sum-header">Body part or system</th>
          <th class="sum-header">
            Impairment Assessment Guidelines (Chapter, page, table/figure)
          </th>
          <th class="sum-header">AMA5 (Chapter, page, table/figure)</th>
          <th class="sum-header">% WPI All assesses impairments</th>
          <th class="sum-header">% WPI Pre-existing impairments</th>
          <th class="sum-header">% WPI Work injury impairment</th>
        </tr>
      </thead>
      <tbody style="font-family: times new roman" class="border-dark">
        <tr v-for="(part, index) in wpiTable" :key="index">
          <td class="text-start text-capitalize">
            {{ part.body_part }}
          </td>
          <td>{{ part.guideline }}</td>
          <td>{{ part.ama }}</td>
          <td>{{ part.wpi }}</td>
          <td>-</td>
          <td>{{ part.wpi }}</td>
        </tr>
      </tbody>

      <tfoot style="font-family: times new roman" class="border-dark">
        <tr>
          <td class="sum-footer" colspan="3">
            Totals (from Combined Values Chart AMA5)
          </td>
          <td class="sum-footer">
            {{ wpiOutput }}% WPI Total all assessed impairments
          </td>
          <td class="sum-footer">-</td>
          <td class="sum-footer">{{ wpiOutput }}% WPI Total work injury</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SummaryTable',
  components: {},

  data() {
    return {
      assessedParts: this.$store.state.extremityCalculations.body_parts,
      wpiOutput: this.$store.state.extremityCalculations.wpi.total,
    }
  },

  computed: {
    wpiTable() {
      let final = []

      let extremityImpairments = this.$store.state.extremityCalculations

      // Display right side first
      if (extremityImpairments.uei.right.length) {
        // If only 1 single part or 1 single finger, then display as original body part's name
        if (
          extremityImpairments.uei.right.length === 1 &&
          extremityImpairments.hi.right.length === 1
        ) {
          final.push({
            body_part: `Right ${extremityImpairments.uei.right[0].body_part}`,
            ama: extremityImpairments.uei.right[0].ama,
            guideline: extremityImpairments.uei.right[0].guideline,
            wpi: extremityImpairments.wpi.right,
          })
        } else
          final.push({
            body_part: `Right total upper extremity`,
            ama: 'Chapter 16, Page 433-521',
            guideline: 'Chapter 2',
            wpi: extremityImpairments.wpi.right,
          })
      }

      if (extremityImpairments.uei.left.length) {
        // If only 1 single part or 1 single finger, then display as original body part's name
        if (
          extremityImpairments.uei.left.length === 1 &&
          extremityImpairments.hi.left.length === 1
        ) {
          final.push({
            body_part: `Left ${extremityImpairments.uei.left[0].body_part}`,
            ama: extremityImpairments.uei.left[0].ama,
            guideline: extremityImpairments.uei.left[0].guideline,
            wpi: extremityImpairments.wpi.left,
          })
        } else
          final.push({
            body_part: `Left total upper extremity`,
            ama: 'Chapter 16, Page 433-521',
            guideline: 'Chapter 2',
            wpi: extremityImpairments.wpi.left,
          })
      }

      // Spine only

      this.assessedParts.forEach((item) => {
        if (item.title.includes('spine')) {
          // Display individual spines

          final.push({
            body_part: item.title,
            guideline: 'Chapter 4',
            ama: 'Chapter 15 Table 15.3, Page 384',
            wpi: item.calculation.wpi,
          })
        }
      })

      return final
    },
  },

  mounted() {},
}
</script>

<style lang="scss">
.summary-table {
  th,
  td {
    padding: 0.625rem;
  }

  .sum-header {
    font-size: 0.75em;
    background-color: rgb(194, 41, 41);
    text-align: left;
    vertical-align: top;
    color: white;
  }

  tbody {
    font-size: 0.8125em;
  }

  .sum-footer {
    font-size: 0.75em;
    background-color: rgba(255, 210, 151, 0.199);
    font-weight: bold;
  }
}
</style>
