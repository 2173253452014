<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="">
    <h1 class="text-primary fs-1 mb-5 text-center">RTWSA Report</h1>
    <div class="p-5 my-5 bg-white shadow">
      <div v-if="pageReady" id="RTWSAREPORT" class="content mb-3">
        <div ref="content">
          <div>
            <div class="col-12">
              <div class="d-block text-end mb-4">
                <img
                  src="@/assets/img/RTWSA_icon.jpg"
                  width="350"
                  alt="RTWSA_icon"
                />
              </div>

              <div v-if="report.referral.referrer" class="my-3">
                <p>
                  {{ convertDateToReportDateFormat(report.date) }}
                </p>
                <p class="text-capitalize">
                  {{ referrerName }}
                </p>
                <p>
                  {{
                    report.referral.referrer.company
                      ? report.referral.referrer.company
                      : '-'
                  }}
                </p>
                <p>
                  {{
                    report.referral.referrer.address
                      ? report.referral.referrer.address
                      : '-'
                  }}
                </p>
              </div>

              <h3 class="fw-bold my-5 text-center">
                Whole Person Impairment Assessment Report - Physical Injuries
              </h3>

              <!-- patient infos -->
              <div class="my-5">
                <div>
                  <p class="my-0">
                    <strong>
                      Name of Injured Worker:

                      {{
                        report.patient.patient_details
                          ? [
                              report.patient.patient_details.first_name,
                              report.patient.patient_details.middle_name,
                              report.patient.patient_details.last_name,
                            ].join(' ')
                          : patientGender
                      }}
                    </strong>
                  </p>
                  <p class="my-0">
                    <strong>
                      Date of birth:
                      {{
                        report.patient.patient_details
                          ? report.patient.patient_details.date_of_birth
                          : '-'
                      }}
                    </strong>
                  </p>
                  <p class="my-0">
                    <strong>
                      Claim number:
                      {{
                        report.patient.claim_number
                          ? report.patient.claim_number
                          : '-'
                      }}
                    </strong>
                  </p>
                  <p class="my-0">
                    <strong>
                      Reference:
                      {{
                        report.referral && report.referral.referrence
                          ? report.referral.referrence
                          : '-'
                      }}
                    </strong>
                  </p>
                  <p class="my-0">
                    <strong>
                      Date of Injury:
                      {{
                        report.patient && report.patient.date_of_injury
                          ? report.patient.date_of_injury
                          : '-'
                      }}
                    </strong>
                  </p>
                </div>
              </div>

              <div>
                <p class="text-capitalize mt-3">
                  Dear
                  <span>{{ referrerName }},</span>
                </p>
                <p class="d-inline">
                  My qualifications to make this report are:

                  {{ doctorProfile.qualifications }}
                </p>

                <p class="my-3">
                  I advise that I have prepared this report in accordance with
                  the South Australian Employment Rule 62 ‘Content of expert
                  reports’ which came into effect on 1 July 2017.
                </p>

                <p>
                  Further to your letter of
                  <span>
                    {{ report.referral ? report.referral.letter_date : '-' }}, I
                    saw
                  </span>

                  {{
                    report.patient.patient_details
                      ? report.patient.patient_details.name
                      : patientGender
                  }}
                  on {{ report.referral.letter_date }} at location for a whole
                  person impairment assessment and report.
                </p>

                <!-- is the patient is accompanied -->
                <div class="my-3">
                  <p v-if="report.patient.is_accompanied" class="d-inline">
                    The patient was accompanied.
                  </p>

                  <p v-else class="d-inline">The patient was unaccompanied.</p>
                  <p v-if="report.patient.has_interpreter" class="d-inline">
                    An interpreter was present at the consultation was present
                    and assisted throughout the consultation.
                  </p>
                  <p v-else class="d-inline">
                    The interpreter was not present at the consultation.
                  </p>
                </div>

                <p>
                  I explained my role as an accredited assessor of permanent
                  impairment and also that my report from this assessment would
                  be sent to ReturnToWorkSA for compliance assessment and then
                  forward to you. This report has been provided in the format
                  required by ReturnToWorkSA.
                </p>

                <p>
                  You have requested an assessment of whole person impairment
                  for the following work injury(ies) (chronologically):
                </p>

                <div v-if="report.referral.request" class="fst-italic">
                  <div v-html="report.referral.request"></div>
                </div>
                <div v-if="selectedParts">
                  <li
                    v-for="part in selectedParts"
                    :key="part"
                    class="text-capitalize"
                  >
                    {{ part }}
                  </li>
                </div>
                <div v-else>
                  <p class="fw-light">
                    There were no referrer requests provided during the
                    assessment.
                  </p>
                </div>
              </div>

              <!-- does the patient has extra deducted assessments -->
              <div v-if="report.deductedAssessments">
                <p>
                  You have requested that I assess and combine the following
                  impairments
                </p>
                <p>-as they result from the same injury or cause; OR</p>
                <p>
                  -as the impairment was caused by a pre-existing work injury
                  which has previously been assessed (and for which the worker
                  has received compensation).
                </p>

                <p>
                  You have requested that I assess and deduct the following
                  impairments as they are unrelated to the work injury(ies):
                </p>

                <p v-if="report.referral.request">
                  {{ report.referral.request }}
                </p>
              </div>

              <div class="fst-italic mt-3">
                <p v-if="report.referral.old_injuries">
                  I also identified other injury(ies) or causes, namely
                  {{ report.oldInjuries }} that result(s) in the worker
                  suffering an impairment of {{ report.oldImpairments }} which
                  pre-existed the date the relevant work injury was sustained,
                  and have assessed and deducted the effect of such pre-existing
                  injury(ies) for the purpose of this assessment.
                </p>
                <p v-else>
                  When assessing impairments resulting from physical injury, no
                  regard is to be had to impairments that result from a
                  psychiatric injury or consequential mental harm.
                </p>
              </div>

              <!-- history -->
              <div class="my-5">
                <p class="my-3 fw-bold fs-4">HISTORY</p>

                <p><strong>Educational and occupational history</strong></p>
                <p
                  v-if="
                    report.patient.occupation_status &&
                    report.patient.occupation
                  "
                >
                  <span class="text-capitalize">
                    {{
                      patientGender === 'he'
                        ? 'Mr'
                        : patientGender === 'she'
                        ? 'Miss'
                        : ''
                    }}
                    {{
                      report.patient.patient_details
                        ? [
                            report.patient.patient_details.first_name,
                            report.patient.patient_details.middle_name,
                            report.patient.patient_details.last_name,
                          ].join(' ')
                        : patientGender
                    }}
                  </span>
                  was employed as a {{ report.patient.occupation_status }}
                  {{ report.patient.occupation.toLowerCase() }} with
                  {{ report.patient.employer_name }} when the injury occurred.
                  <br />

                  At the time of the assessment the patient was
                  {{
                    report.patient.current_employment_status === 'W'
                      ? 'working'
                      : report.patient.current_employment_status === 'NW'
                      ? 'not working'
                      : ''
                  }}.
                  <br />
                  <!-- if patient not working do not display workhours -->
                  <span v-if="report.patient.current_employment_status === 'W'">
                    <span class="text-capitalize">{{ patientGender }}</span>
                    worked
                    {{ report.patient.work_hours }} hours per week.
                  </span>
                </p>
                <p v-else>
                  Patient did not provide their occupational history information
                  at the time of the assessment.
                </p>

                <hr class="my-5" />

                <p><strong>Social and personal history</strong></p>
                <p v-if="report.patient.social_status">
                  <span class="text-capitalize">
                    {{
                      patientGender === 'he'
                        ? 'Mr'
                        : patientGender === 'she'
                        ? 'Miss'
                        : ''
                    }}
                    {{
                      report.patient.patient_details
                        ? [
                            report.patient.patient_details.first_name,
                            report.patient.patient_details.middle_name,
                            report.patient.patient_details.last_name,
                          ].join(' ')
                        : patientGender
                    }}
                  </span>
                  informed me that {{ patientGender }} was
                  {{ report.patient.social_status }} at the time of the
                  assessment.
                </p>
                <p v-else>
                  Patient did not provide their social and personal history
                  information at the time of the assessment.
                </p>

                <hr class="my-5" />

                <p><strong>Past medical history</strong></p>
                <p class="">
                  <span class="text-capitalize">
                    {{
                      patientGender === 'he'
                        ? 'Mr'
                        : patientGender === 'she'
                        ? 'Miss'
                        : ''
                    }}
                    {{
                      report.patient.patient_details
                        ? [
                            report.patient.patient_details.first_name,
                            report.patient.patient_details.middle_name,
                            report.patient.patient_details.last_name,
                          ].join(' ')
                        : patientGender
                    }}
                  </span>
                  reported that {{ patientGender }} has
                  {{
                    report.patient.past_history.length > 0
                      ? 'medical history.'
                      : 'no medical history in the past.'
                  }}
                </p>

                <ul v-if="report.patient.past_history.length > 0">
                  <li v-for="item in report.patient.past_history" :key="item">
                    {{ item }}
                  </li>
                </ul>

                <div class="mt-5">
                  <p class="text-decoration-underline">
                    History of present medical condition(s)
                  </p>
                  <p>I note that your referral had the following history:</p>
                  <!-- this section is for referral request -->
                  <div v-if="report.referral.history" class="fst-italic">
                    <div v-html="report.referral.history"></div>
                  </div>
                  <div v-else>
                    <p class="fw-light">
                      There was no referral history provided.
                    </p>
                  </div>

                  <!-- this section is for injury_treatment_histories -->
                  <!-- patient medical history or records -->
                  <div
                    v-if="report.patient.injury_treatment_histories.length > 0"
                  >
                    <p>
                      {{
                        patientGender === 'he'
                          ? 'Mr'
                          : patientGender === 'she'
                          ? 'Miss'
                          : ''
                      }}
                      {{
                        report.patient.patient_details
                          ? [
                              report.patient.patient_details.first_name,
                              report.patient.patient_details.middle_name,
                              report.patient.patient_details.last_name,
                            ].join(' ')
                          : patientGender
                      }}
                      confirmed the above history and provided the following
                      additional history:
                    </p>
                    <div
                      v-for="(info, idx) in report.patient
                        .injury_treatment_histories"
                      :key="idx"
                    >
                      <div class="pb-3">
                        <p class="text-decoration-underline fst-italic">
                          {{ idx + 1 }}.
                          {{
                            info.injury_date
                              ? info.injury_date + ' - '
                              : 'Unknown Date'
                          }}
                          <span class="text-capitalize">
                            {{ info.treatment_referral ?? ' ' }}
                          </span>
                        </p>

                        <div>
                          <p v-if="info.injury_cause" class="d-inline">
                            <span class="text-capitalize">
                              {{ patientGender }}
                            </span>
                            was
                            {{ info.injury_cause }}.
                          </p>
                          <p v-if="info.symptoms">
                            Patient at the time was experiencing
                            {{ info.symptoms }} .
                          </p>

                          <p v-if="info.specialist_treatment" class="d-inline">
                            <span class="text-capitalize">
                              {{ patientGender }}
                            </span>

                            sought medical attention from
                            {{ info.initial_treatment_person_name }},
                            <span v-if="info.specialist_treatment">
                              specialist of {{ info.specialist_treatment }},
                            </span>
                            who arranged the following since then:
                          </p>
                        </div>
                        <p v-if="info.tests" class="d-inline">
                          {{ info.tests }}
                        </p>
                        <p v-if="info.later_symptoms" class="d-inline">
                          <span class="text-capitalize">
                            {{ patientGender }}
                          </span>
                          then experienced {{ info.later_symptoms }}.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="my-3">
                    The patient did not provide treatments and injuries history
                    during time of assesment.
                  </div>

                  <hr class="my-5" />

                  <!-- symptoms -->
                  <div class="my-5">
                    <p><strong>Current symptoms</strong></p>

                    <!-- •	Constant 6-9 out of 10 lumbar spine pain worsen with vacuuming, mopping and gardening. She avoided these tasks and her partner had been helping her. She is able to bend down to change shoes and socks -->
                    <div
                      v-if="
                        !report.patient.symptoms.every(
                          (x) => x.isEmpty === true
                        )
                      "
                    >
                      <p class="pb-4">
                        At the time of the assessment,
                        {{
                          patientGender === 'he'
                            ? 'Mr'
                            : patientGender === 'she'
                            ? 'Miss'
                            : ''
                        }}
                        {{
                          report.patient.patient_details
                            ? [
                                report.patient.patient_details.first_name,
                                report.patient.patient_details.middle_name,
                                report.patient.patient_details.last_name,
                              ].join(' ')
                            : patientGender
                        }}
                        reported the ongoing symptoms:
                      </p>
                      <ul
                        v-for="(symptom, index) in report.patient.symptoms"
                        :key="index"
                      >
                        <li v-if="!symptom.isEmpty">
                          <p class="text-capitalize">
                            <u>
                              {{
                                symptom.body_part
                                  ? symptom.body_part
                                  : symptom.location
                                  ? symptom.location
                                  : 'Patient did not provide specific location of the symptom'
                              }}
                            </u>
                          </p>
                          <div
                            v-if="
                              symptom.min_pain_score &&
                              symptom.max_pain_score !== 0
                            "
                            class="d-inline mb-3"
                          >
                            <p class="d-inline">
                              <span class="text-capitalize">
                                {{
                                  symptom.pain_frequency === 'C'
                                    ? 'Constant,'
                                    : symptom.pain_frequency === 'I'
                                    ? 'Intermittent,'
                                    : ''
                                }}
                              </span>

                              <span v-if="symptom.pain_frequency === 'I'">
                                {{
                                  symptom.intermittent_frequency.duration_one
                                }}
                                {{
                                  symptom.intermittent_frequency.duration_two
                                }},
                                {{ symptom.intermittent_comments }}
                              </span>
                              {{ 'pain of ' + symptom.min_pain_score }} -
                              {{ symptom.max_pain_score }} at
                              {{ symptom.location }} and most of the time
                              {{ symptom.majority_pain_score }} out of 10 in
                              severity.
                            </p>
                          </div>
                          <div v-else class="d-inline">
                            Patient did not provide information regarding their
                            pain frequency.
                          </div>
                          <p v-if="symptom.aggravating_factors.length > 0">
                            Pain was worsened with
                            {{
                              formatLongConjunction(
                                symptom.aggravating_factors
                              )
                            }}.
                          </p>
                          <p v-else>
                            The patient did not report of any aggravating factor
                            or loss of activities of living.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div v-else>
                      <p>
                        The patient did not report any ongoing symptoms. The
                        patient did not report of any aggravating factor or loss
                        of activities of living.
                      </p>
                    </div>
                  </div>

                  <hr class="my-5" />

                  <!-- ongoing treatments -->

                  <div>
                    <p><strong>Current treatments</strong></p>
                    <div v-if="report.patient.current_treatment">
                      <ul>
                        <li>{{ report.patient.current_treatment }}</li>
                      </ul>
                    </div>

                    <div v-else>Patient has no ongoing treatments.</div>
                  </div>

                  <hr class="my-5" />

                  <!-- ongoing medications -->

                  <div>
                    <p><strong>Current medication(s)</strong></p>
                    <div v-if="report.patient.current_medication">
                      <ul>
                        <li>{{ report.patient.current_medication }}</li>
                      </ul>
                    </div>
                    <div v-else>Patient has no ongoing medication.</div>
                  </div>

                  <hr class="my-5" />
                </div>

                <p><strong>Relevant Imagings</strong></p>
                <div
                  v-if="
                    report.patient.consultation.has_relevant_radiology_images
                  "
                >
                  <p>
                    Patient has provided relevant radiology imagings for the
                    relevant assessments
                  </p>
                </div>

                <p v-else>Not applicable.</p>

                <hr class="my-5" />

                <div v-if="report.consultation">
                  <p><strong>History of any compensable events</strong></p>
                  <p v-if="report.consultation.compensable_history">
                    {{ report.consultation.compensable_history }}
                  </p>
                  <p v-else>
                    Patient did not report of any compensable events.
                  </p>

                  <hr class="my-5" />

                  <p>
                    <strong>Any pre-existing injuries or conditions</strong>
                  </p>
                  <p v-if="report.consultation.has_preinjury_history">
                    {{
                      report.consultation.preinjury_history
                        ? report.consultation.preinjury_history
                        : 'Patient had pre-existing injuries or conditions, but was unable to provide more information.'
                    }}
                  </p>
                  <p v-else>
                    The patient did not have any other significantly relevant
                    prior injury.
                  </p>

                  <hr class="my-5" />
                </div>

                <div v-else>
                  <p>
                    There was no history of compensable events or pre-existing
                    injuries or conditions recorded
                  </p>
                </div>
              </div>
              <!-- document review -->
              <div class="my-5">
                <p class="my-3 fw-bold fs-4">DOCUMENT REVIEW</p>
                <div v-if="report.referral.enclosed_docs" class="pb-5">
                  <p>I confirm I reviewed the following documents provided:</p>

                  <div
                    class="my-2"
                    v-html="report.referral.enclosed_docs"
                  ></div>
                </div>
                <div v-else class="pb-5">
                  <p class="fw-light">
                    There is no relevant enclosed documents provided during the
                    assessments.
                  </p>
                </div>
              </div>

              <!-- examination -->
              <div class="my-5">
                <p class="my-3 fw-bold fs-4">EXAMINATION</p>

                <span v-if="report.consultation.did_patient_cooperate">
                  The patient was cooperative during the assessment.
                </span>
                <span v-else>
                  There is no recording on the cooperativeness of the patient
                  during time of assessment.
                </span>
                <span>
                  Movements, unless indicated otherwise, were measured in the
                  active range and on the best out of 3 attempts. The
                  examination was performed as per the AMA5 requirements.
                </span>
              </div>

              <div
                v-if="assessedParts.length > 0"
                class="mx-auto col-12 pt-3 my-3"
              >
                <div v-for="item in assessedParts" :key="item" class="my-5">
                  <div v-if="item.title.includes('spine') && item.spine_data">
                    <SpineReportTable
                      :table-data="{
                        spineData: {
                          muscles: item.spine_data.muscles,
                          ...item.spine_data.radiculopathy,
                        },
                        tableRows: item.spine_data.impairment,
                        tableHead: ['Motion', ''],

                        patientGender: patientGender,
                        spineName: item.spine_data.spine_name,
                      }"
                    />
                  </div>
                  <div v-if="!item.title.includes('spine') && !item.spine_data">
                    <FingersReportTable
                      v-if="
                        item.title.includes('finger') ||
                        item.title.includes('thumb')
                      "
                      :table-data="item"
                    />

                    <DefaultReportTable v-else :table-data="item" />
                  </div>
                </div>
              </div>

              <br />
            </div>
            <!-- opinion -->
            <div class="my-5">
              <p class="my-3 fw-bold fs-4">OPINION</p>
              <p class="fw-bold text-decoration-underline">
                Diagnosis/Diagnoses:
              </p>
              <div>
                <p>
                  The patient provided a history with examination findings and
                  radiological findings consistent with the following:
                </p>
                <div v-for="item in assessedParts" :key="item">
                  <div v-if="item.spine_name">
                    <p class="my-3">
                      <strong class="text-capitalize">
                        {{ item.spine_name }} :
                      </strong>

                      {{
                        item.diagnosis.specific.length
                          ? capitalizeFirstWord(
                              item.diagnosis.specific.join(', ')
                            )
                          : 'No specific diagnosis provided.'
                      }}
                    </p>
                  </div>
                  <div v-else>
                    <div v-for="side in item.direction" :key="side">
                      <p class="my-3">
                        <strong class="text-capitalize">
                          {{ side + ' ' + item.title }} :
                        </strong>

                        {{
                          item.specific_diagnosis[side]
                            ? capitalizeFirstWord(item.specific_diagnosis[side])
                            : 'No specific diagnosis provided.'
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                The above diagnosis was formulated from the provided history,
                examination finding and the evidence from the enclosed
                documents.
              </div>
              <p class="my-4 fw-bold text-decoration-underline">
                Determination of maximum medical improvement for each work
                injury assessed:
              </p>
              <p>
                The patient’s condition has been stable over the past three
                months and is not expected to change significantly in the
                foreseeable future. Further recovery or deterioration is not
                anticipated because the patient’s history and clinical findings
                indicated a medical condition is static and well stabilised, but
                can include temporary fluctuations. This qualifies the
                requirement of Paragraph 1.13 of Return To Work Impairment
                Assessment (RTWIA) Guidelines, Page 5, for MMI.
              </p>
            </div>

            <!-- ratable impairments -->
            <div class="my-5 pt-4">
              <p class="my-3 fw-bold fs-4">RATEABLE IMPAIRMENTS</p>

              <p class="mt-4 fw-bold text-decoration-underline">
                Impairment assessment for each work injury listed:
              </p>

              <div class="mx-auto my-5">
                <div
                  v-for="item in assessedParts"
                  :key="item"
                  class="my-5 table-responsive"
                >
                  <div v-if="item.title.includes('spine') && item.spine_data">
                    <!-- Don't load this if frontend doesn't have data -->
                    <p
                      class="text-capitalize text-decoration-underline fw-bold"
                    >
                      {{ item.spine_name }}
                    </p>
                    <SpineOutputs
                      :gender="patientGender ? patientGender : 'patient'"
                      :data="item.spine_data"
                    />
                  </div>
                  <div v-if="!item.title.includes('spine')">
                    <p
                      class="text-capitalize text-decoration-underline fw-bold"
                    >
                      {{ item.title }}
                    </p>
                    <p>
                      The range of motion loss is assessed as per
                      {{ item.ama }} of AMA5, which allocate the following
                      impairment:
                    </p>
                    <FingersImpairmentTable
                      v-if="
                        item.title.includes('finger') ||
                        item.title.includes('thumb')
                      "
                      :table-data="item"
                    />
                    <DefaultImpairmentTable v-else :table-data="item" />
                  </div>
                </div>
              </div>
            </div>

            <!-- ueier table -->

            <div v-if="ueierRuleCheck.side.length > 0">
              <div v-for="direction in ueierRuleCheck.side" :key="direction">
                <p class="fw-bold text-decoration-underline">
                  Total
                  <span class="text-capitalize">{{ direction }}</span>
                  Upper Extremity Impairment Calculation
                </p>
                <p>As per the AMA5 Guides, the calculation is as follows:</p>
                <br />
                <div
                  v-if="
                    ueierRuleCheck[`${direction}_table_data`].hand.length > 0
                  "
                  class="my-3"
                >
                  <!-- ueier fingers only table -->
                  <p class="py-2 ueier-title text-capitalize">
                    <strong>Figure 16-1a</strong>
                    {{ direction }} Upper Extremity Impairment Evaluation
                    Record-
                    <strong>Part 1 (Hand)</strong>

                    <span>
                      &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
                      &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
                      &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
                    </span>

                    <span class="float-end">
                      Side
                      <UeierRadioCheckboxGroup
                        :checked="direction === 'left' ? 'L' : 'R'"
                        :options="sidesOption"
                      />
                    </span>
                  </p>
                  <UeierHeader
                    class="ueier-patient-details"
                    :patient-data="report.patient"
                  />
                  <UeierHandTable
                    :table-data="ueierRuleCheck[`${direction}_table_data`].hand"
                    :direction="direction"
                  />
                </div>

                <br />

                <div
                  v-if="
                    ueierRuleCheck[`${direction}_table_data`].limb.length > 0
                  "
                  class="my-3"
                >
                  <!-- ueier elbow shoulder wrist only table -->
                  <p class="py-2 ueier-title text-capitalize">
                    <strong>Figure 16-1b</strong>
                    {{ direction }} Upper Extremity Impairment Evaluation
                    Record-
                    <strong>Part 2 (Wrist, elbow, and shoulder)</strong>

                    <span>
                      &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
                      &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
                    </span>

                    <span class="float-end">
                      Side
                      <UeierRadioCheckboxGroup
                        :checked="direction === 'left' ? 'L' : 'R'"
                        :options="sidesOption"
                      />
                    </span>
                  </p>
                  <UeierHeader
                    class="ueier-patient-details"
                    :patient-data="report.patient"
                  />
                  <UeierLimbTable
                    :table-data="ueierRuleCheck[`${direction}_table_data`].limb"
                  />
                </div>

                <UeierCalculationFooter :direction="direction" />
              </div>
              <br />
            </div>

            <SummaryTable />
          </div>

          <div class="report-end my-4">
            <p style="font-size: 0.75em" class="fst-italic mx-4 fw-light">
              Please note that the Total calculation (is not simply added) is a
              mathematical calculation as per page 604 of AMA 5.The combination
              table(s) is(are) as per the referral request (Paragraph 1.20 of
              the Return To Work Scheme Impairment Assessment Guidelines, Page
              6). If a different format is required, please request for an
              amended report.
            </p>

            <p>
              The contents of this report are true to the best of my knowledge
              and belief. This report has been written in accordance with the
              current edition of the ReturnToWork Impairment Assessment
              Guidelines, the AMA5, the RTWSA report template (effective 1
              November 2017) and the information made available on the
              assessment date above. Should new information be made available, a
              new report should be requested. I have, to my knowledge, not
              withheld any information.
            </p>
          </div>

          <div class="mt-3">
            <p class="mb-1">Yours sincerely,</p>
            <div>
              <img
                :src="require(`@/assets/img/${doctorProfile.signature}`)"
                class=""
              />
            </div>
            <span
              class="fw-bold text-uppercase d-block text-decoration-underline tx-sm"
            >
              {{ doctorProfile.name }}
            </span>

            <p style="font-size: 0.8125em" class="fw-bold my-0">
              {{ doctorProfile.occupation }}
            </p>
            <p style="font-size: 0.8125em" class="fw-bold my-0">
              Certified Independent Medical Examiner (CIME of ABIME) for AMA4,
              AMA5, AMA6 and Comcare Return to Work SA Accredited impairment
              assessor of Upper Extremity, Lower Extremity, Respiratory, Spine
              and Skin
              <br />
              Independent Medical Advisor of the South Australia Employment
              Tribunal.
            </p>
            <br />
          </div>

          <div id="extra-informations" class="">
            <span class="text-decoration-underline d-block fst-italic fw-bold">
              Legend
            </span>

            <div class="fw-bold fst-italic">
              <p class="my-0">LEI = Lower extremity impairment</p>

              <p class="my-0">UEI = Upper extremity impairment</p>

              <p class="my-0">WPI = Whole person impairment</p>

              <p class="my-0">
                IAGs= Return To Work Scheme Impairment Assessment Guidelines
              </p>

              <p class="my-0">- = No calculation from the AMA 5 and IAGs</p>
            </div>
            <p style="font-size: 0.8125em; color: red" class="fw-bold my-2">
              This report was generated by Dragon Medical, a voice-activated
              system, and therefore may contain some incorrect recognised
              speech.
            </p>
          </div>
        </div>
        <div style="text-align: center" class="my-3">
          <button
            class="mr-1 btn btn-primary text-white my-3 px-5"
            type="button"
            @click="copy"
          >
            Copy
          </button>
        </div>
      </div>
      <div v-else class="spinner-border text-primary" role="status">
        <span class="sr-only" />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultReportTable from '@/components/Report/DefaultReportTable.vue'
import DefaultImpairmentTable from '@/components/Report/DefaultImpairmentTable.vue'
import FingersReportTable from '@/components/Report/FingersReportTable.vue'
import SpineReportTable from '@/components/Report/SpineReportTable.vue'
import FingersImpairmentTable from '@/components/Report/FingersImpairmentTable.vue'
import SpineOutputs from '@/components/Report/SpineOutput.vue'
import SummaryTable from '@/components/Report/SummaryTable.vue'

import UeierRadioCheckboxGroup from '@/components/Report/UEIER/UeierRadioGroup.vue'
import UeierHandTable from '@/components/Report/UEIER/UeierHandTable.vue'
import UeierLimbTable from '@/components/Report/UEIER/UeierLimbTable.vue'
import UeierHeader from '@/components/Report/UEIER/UeierHeaderPatientDetails.vue'

import dayjs from 'dayjs'
import UeierCalculationFooter from '@/components/Report/UEIER/UeierCalculationFooter.vue'

import {
  formatLongConjunction,
  convertDateToAuFormat,
} from '@/utils/generics.js'

export default {
  name: 'RtwsaReport',
  components: {
    DefaultReportTable,
    DefaultImpairmentTable,
    FingersReportTable,
    FingersImpairmentTable,
    SpineReportTable,
    SpineOutputs,
    SummaryTable,
    UeierHandTable,
    UeierLimbTable,
    UeierHeader,
    UeierRadioCheckboxGroup,
    UeierCalculationFooter,
  },
  props: { assessmentId: { type: Number, default: null } },

  data() {
    return {
      //temporary only on front end
      doctorProfile: {
        name: 'Dr Danh Tran',
        signature: 'DahnSignaturePicture1.jpg',
        occupation:
          'Consultant Occupational and Environmental Physician (FAFOEM of RACP)',
        qualifications:
          'I am a Fellow of the Faculty of Occupational and Environmental Medicine of the Royal Australasian College of Physicians with post fellowship study in musculoskeletal disorders and nerve conduction studies.  I am a Certified Independent Medical Examiner of the American Board of Independent Medical Examiners (ABIME) in AMA Guides 4, 5 and 6 Editions since 2010 and last completed my 5 yearly Board’s recertification in 2020.  I am an accredited Permanent Impairment assessor for the Upper Extremities, Lower Extremities, Spine and Respiratory and Digestive (limited) systems with the RTWSA and an Independent Medical Advisor (IMA) of the SA Employment Tribunal.  ',
      },

      report: {
        referral: null,
        patient: null,
        consultation: null,
        profile: {
          qualifications: null,
        },
      },
      spineOutputDataAry: null,
      // Display a list of only selected parts
      selectedParts: [],
      workStatus: [
        { key: 'PT', value: 'part time' },
        { key: 'FT', value: 'full time' },
        { key: 'UE', value: 'unemployed' },
      ],
      socialStatus: [
        { key: 'S', value: 'single' },
        { key: 'M', value: 'married' },
        { key: 'D', value: 'divorced' },
        { key: 'W', value: 'widowed' },
      ],

      sidesOption: ['L', 'R'],

      partsAry: [],

      patientGender: null,
      pageReady: false,
    }
  },

  computed: {
    referrerName() {
      return this.report.referral.referrer
        ? [
            this.report.referral.referrer.title,
            this.report.referral.referrer.first_name,
            this.report.referral.referrer.middle_name,
            this.report.referral.referrer.last_name,
          ].join(' ')
        : '-'
    },
    wpiOutput: function () {
      return this.$store.state.extremityCalculations.wpi.total
    },

    assessedParts() {
      return this.$store.state.extremityCalculations.body_parts
    },

    ueierRuleCheck() {
      //Only show the tables when there are at least selected 2 parts on the same direction,
      //and show the table as Left Upper Extremity Impairment
      //or Right Upper Extremity Impairment depending on the direction of the body part.
      //If there are 2 or more body parts on left and right, display 2 separate tables for left and right.
      let result = {
        side: [],
        left_table_data: {
          hand: [],
          limb: [],
        },
        right_table_data: {
          hand: [],
          limb: [],
        },
      }

      //Check total sides

      let totalLeft = 0
      let totalRight = 0

      //Filter body parts into their category
      //the components will later convert it's recieved array of fields into object for mapping

      this.assessedParts.forEach((part) => {
        if (part.direction.includes('left')) {
          // Fingers only
          if (part.title.includes('finger') || part.title.includes('thumb')) {
            result.left_table_data.hand.push({
              fields: part.left,
              name: part.title.replace(' ', '_'),
              calculations: part.left_calculation,
            })
          }

          // Limb only
          if (
            !part.title.includes('finger') &&
            !part.title.includes('spine') &&
            !part.title.includes('thumb')
          ) {
            result.left_table_data.limb.push({
              fields: part.left,
              name: part.title.replace(' ', '_'),
              calculations: part.left_calculation,
            })
          }

          totalLeft += 1
        }
        if (part.direction.includes('right')) {
          // Fingers only
          if (part.title.includes('finger') || part.title.includes('thumb')) {
            result.right_table_data.hand.push({
              fields: part.right,
              name: part.title.replace(' ', '_'),
              calculations: part.right_calculation,
            })
          }

          // Limb only
          if (
            !part.title.includes('finger') &&
            !part.title.includes('spine') &&
            !part.title.includes('thumb')
          ) {
            result.right_table_data.limb.push({
              fields: part.right,
              name: part.title.replace(' ', '_'),
              calculations: part.right_calculation,
            })
          }

          totalRight += 1
        } else return false
      })

      // Direction rule

      // Display right side first

      if (totalRight >= 2) {
        result.side.push('right')
      }

      if (totalLeft >= 2) {
        result.side.push('left')
      }

      return result
    },
  },

  async mounted() {
    //import spineData temporary

    this.spineOutputDataAry = this.$store.state.spineData

    this.report.date = new Date()
    await this.fetchAssessmentData()
  },

  unmounted() {
    // Ensure quitting report page,
    // resets temporaray front end data of spine

    this.$store.state.spineData = []
  },

  methods: {
    copy() {
      if (this.pageReady) {
        this.$nextTick(() => {
          let div = document.createElement('div')
          div.innerHTML = this.$refs.content.innerHTML

          div.style.position = 'fixed'

          div.style.top = 0
          div.style.left = 0
          div.style.pointerEvents = 'none'
          div.style.background = 'transparent'
          div.style.width = '550px'

          div.style.fontFamily = 'times new roman'

          document.body.appendChild(div)
          let table = div.getElementsByClassName('summary-table')

          // Specific formatting for ueier
          let ueier = div.getElementsByClassName('ueier-table')

          // These below share similar styling update
          let ueierNonTable = div.querySelectorAll(
            '.ueier-title,.ueier-citations,.ueier-patient-details'
          )

          // Update img width so it doesn't break the formatting for word doc
          let ueierImgs = div.getElementsByClassName('ueier-img')

          // Special width for upper limb img
          let ueierLimb = div.getElementsByClassName('ueier-img-limb')

          if (table[0].offsetWidth > 600) {
            table[0].style.width = '600px'
          }

          // Word doc referrence uses 6.5pt
          if (ueier) {
            Array.from(ueier).forEach((table) => {
              table.style.width = '650px'
              table.style.fontSize = '8.6px'
            })
          }

          if (ueierNonTable) {
            Array.from(ueierNonTable).forEach((e) => {
              e.style.fontSize = '8.6px'
            })
          }

          if (ueierImgs) {
            Array.from(ueierImgs).forEach((img) => {
              img.setAttribute('width', '80')
            })
          }

          if (ueierLimb) {
            Array.from(ueierLimb).forEach((img) => {
              img.setAttribute('width', '122')
            })
          }

          this.selectText(div)
          document.execCommand('copy')
          document.body.removeChild(div)
        })
      }
    },

    capitalizeFirstWord(word) {
      let firstLetter = word.charAt(0)

      //remove and add in capitalized letter to the original sentence
      return firstLetter.toUpperCase() + word.slice(1)
    },

    convertDateToReportDateFormat(date) {
      if (date) {
        const result = dayjs(date).format('D MMMM YYYY')

        return result
      } else return null
    },

    selectText(element) {
      var range
      if (document.selection) {
        // IE
        range = document.body.createTextRange()
        range.moveToElementText(element)
        range.select()
      } else if (window.getSelection) {
        range = document.createRange()
        range.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
    },
    range(start, stop, step) {
      if (typeof stop == 'undefined') {
        // one param defined
        stop = start
        start = 0
      }

      if (typeof step == 'undefined') {
        step = 1
      }

      if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return []
      }

      var result = []
      for (var i = start; step > 0 ? i <= stop : i > stop; i += step) {
        result.push(i)
      }

      return result
    },

    formatLongConjunction(ary) {
      return formatLongConjunction(ary)
    },

    async fetchAssessmentData() {
      let payload = {}
      payload.id = parseInt(this.$route.params.id)

      await this.$store
        .dispatch(`getAssessment`, payload)
        .then((response) => {
          const data = response.data

          this.partsAry = data.body_parts

          // Process assessment data

          // Map consultation, doctor info, patient info, referrals

          this.report.patient = data.patient
          this.report.referral = data.referral
          this.report.consultation = data.patient.consultation
          this.report.consultation_date = convertDateToAuFormat(
            data.date_time_created
          )

          // Remap some raw values into proper english sentences

          // Cccupation status at the time of injury
          this.report.patient.occupation_status = this.workStatus.find(
            (x) => x.key === this.report.patient.occupation_status
          )
            ? this.workStatus.find(
                (x) => x.key === this.report.patient.occupation_status
              ).value
            : 'employee in a company that the patient could not remember'

          // Social status, married or single?
          this.report.patient.social_status = this.socialStatus.find(
            (x) => x.key === this.report.patient.social_status
          )
            ? this.socialStatus.find(
                (x) => x.key === this.report.patient.social_status
              ).value
            : 'not comfortable sharing their social status or personal history'

          this.report.patient.symptoms.forEach((s) => {
            // Display assessed body parts
            // The backend will always displayed selected body part for individual part's symptom input
            this.selectedParts.push(s.body_part)

            if (
              !s.pain_frequency &&
              !s.min_pain_score &&
              !s.max_pain_score &&
              s.aggravating_factors.length === 0
            ) {
              // Inject isEmpty check on all symptoms
              s['isEmpty'] = true
            } else s['isEmpty'] = false
          })

          //this is for stage4 ADL for spine ( only update if detected spine inputs)
          //temporary only allow lumbar spine
          if (this.report.patient && this.spineOutputDataAry.length > 0) {
            this.spineOutputDataAry.forEach((spine) => {
              let obj = {
                ...spine,
                symptoms: this.report.patient.symptoms.find((x) => {
                  return x.body_part.toLowerCase() === spine.spine_name
                }),
                // Temporary add empty direction array and WPI ( to maintain consistency as other body part data format ) as spine has no side

                direction: [],
                wpi: 0,
              }

              this.assessedParts.push(obj)
            })
          }
          if (this.report.patient.patient_details) {
            //fetch patient basic info that was linked to assessment

            this.patientGender =
              this.report.patient.patient_details.gender === 'M' ? 'he' : 'she'
          } else this.patientGender = 'the patient'

          // Ensure the array is sorted in ascending order by ID ,
          // This is because the backend will always output same element's name side by side before the next
          // Array needs to look like [ a , a , b , b ]

          this.partsAry.sort((a, b) => {
            if (a.id > b.id) return 1
            if (a.id < b.id) return -1
            return 0
          })

          // Merge frontend data onto spine related backend data

          if (this.spineOutputDataAry.length > 0) {
            this.spineOutputDataAry.forEach((spine) => {
              let foundBackendSpineIndex = this.partsAry.findIndex(
                (e) => e.display_name.toLowerCase() === spine.spine_name
              )

              let frontEndSpineData = {
                ...spine,
                symptoms: this.report.patient.symptoms.find((x) => {
                  return x.body_part.toLowerCase() === spine.spine_name
                }),
              }

              // Update data with frontend processed spine data
              this.partsAry[foundBackendSpineIndex][`spine_data`] =
                frontEndSpineData
            })
          }

          // This.partsAry = response.data.body_parts
          // Temporary add spine data from frontend

          // Calculate
          this.$store.commit('CALCULATE_IMPAIRMENTS', this.partsAry)

          this.pageReady = true
        })
        .catch((error) => {
          console.log(error)
          // this.pageReady = true
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#RTWSAREPORT {
  font-family: 'Times New Roman', Times, serif !important;
  font-size: 1em;
  line-height: 110%;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.title {
  margin-bottom: 0.625rem;
}
.part-title {
  margin-top: 1.25rem;
}
.row-items {
  margin-bottom: 0.625rem;
}

.ueier {
  &-title {
    border-style: double none;
  }

  &-patient-details,
  &-title {
    font-size: 12px;
  }
}
</style>
