<template>
  <span v-for="option in options" :key="option" style="display: inline">
    <span v-if="checked === option">&#9745;</span>
    <span v-else>&#9744;</span>
    <span style="margin: 0; padding-right: 5px">
      {{ option }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'UeierRadioGroup',

  props: {
    checked: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
