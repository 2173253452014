<template>
  <div class="table-responsive">
    <table class="ueier-table table table-bordered border-dark">
      <thead class="align-top">
        <tr class="text-center">
          <th colspan="6">Abnormal Motion</th>
          <th colspan="2">Other Disorders</th>
          <th colspan="2">Regional Impairment %</th>
          <th colspan="2">Amputation</th>
        </tr>
        <tr>
          <td colspan="6">
            Record motion or ankyloses angels and digit impairment %
          </td>
          <td colspan="2">List type &Impairment %</td>
          <td colspan="2">*Combine [1] + [2]</td>
          <td colspan="2">Mark type, level, & impairment %</td>
        </tr>
      </thead>

      <ShoulderWristElbowTableBody :table-body="limbTableBodyData" />
    </table>
  </div>
</template>

<script>
import ShoulderWristElbowTableBody from '@/components/Report/UEIER/ShoulderWristElbowTableBody.vue'
//table field ordering
//   import FieldOrders from '@/assets/files/ImpairmentTableFieldsOrder.json'
export default {
  name: 'UeierLimbTable',
  components: {
    ShoulderWristElbowTableBody,
  },

  props: {
    tableData: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {}
  },

  computed: {
    limbTableBodyData() {
      let final = {
        elbow: null,
        shoulder: null,
        wrist: null,
      }

      if (this.tableData) {
        this.tableData.forEach((element) => {
          let obj = {}
          element.fields.forEach(({ degree, field_name, impairment_value }) => {
            //ensure no space in between key name
            obj[`${field_name.replace(/ /g, '_')}_degree`] = degree
            return (obj[`${field_name.replace(/ /g, '_')}_impairment`] =
              impairment_value)
          })
          return (final[element.name] = { ...obj })
        })

        return final
      } else return null
    },
  },
}
</script>

<style lang="scss" scoped>
.ueier {
  &-table {
    font-size: 12px;
    thead {
      td,
      th {
        padding: 8px;
      }
    }
    :deep(tbody) {
      border-top: 0 !important;

      tr {
        td,
        th {
          padding: 8px;
        }
        .rotate {
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          width: 1.5em;
        }
        .rotate div {
          transform: rotate(-90deg); /* Saf3.1+, Chrome */
          filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083); /* IE6,IE7 */
          -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)'; /* IE8 */
          margin-left: -10em;
          margin-right: -10em;
          font-weight: 600;
        }
      }

      .ueier-img {
        &-limb {
          max-height: 750px;
          // 250 x 3 ( elbow, wrist, shoulder )
        }
      }
    }
  }

  &-citations {
    font-size: 12px;
  }
}
</style>
