<template>
  <tbody class="table-white table-bordered border-dark align-baseline">
    <!-- wrist  only -->
    <tr>
      <td class="rotate" rowspan="8">
        <div>Wrist</div>
      </td>
      <td></td>
      <td>Flexion</td>
      <td>Extension</td>
      <td>Ankylosis</td>
      <td>Imp %</td>

      <td rowspan="6" colspan="2"></td>
      <!-- combined value needed for regional impairment -->
      <td rowspan="8" colspan="2" valign="middle" align="center">
        {{ wristRegionalImpairment }}
      </td>
      <td rowspan="26" colspan="2" valign="middle" align="center">
        <img
          class="ueier-img-limb"
          src="@/assets/img/ueier/limb_bone.png"
          alt="upper limbs image"
          style="width: auto; height: auto"
        />
      </td>
    </tr>
    <tr>
      <td>Angle°</td>
      <td>{{ tableBody?.wrist?.flexion_degree ?? '' }}</td>
      <td>{{ tableBody?.wrist?.extension_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody?.wrist?.flexion_impairment ?? '' }}</td>
      <td>{{ tableBody?.wrist?.extension_impairment ?? '' }}</td>
      <td></td>

      <td>
        {{
          validImp(
            tableBody?.wrist?.flexion_impairment +
              tableBody?.wrist?.extension_impairment
          )
        }}
      </td>
    </tr>
    <tr>
      <td></td>
      <td>RD</td>
      <td>UD</td>
      <td>Ankylosis</td>
      <td>Imp%</td>
    </tr>
    <tr>
      <td>Angle°</td>
      <td>{{ tableBody?.wrist?.radial_deviation_degree ?? '' }}</td>
      <td>{{ tableBody?.wrist?.ulnar_deviation_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody?.wrist?.radial_deviation_impairment ?? '' }}</td>
      <td>{{ tableBody?.wrist?.ulnar_deviation_impairment ?? '' }}</td>
      <td></td>

      <td>
        {{
          validImp(
            tableBody?.wrist?.radial_deviation_impairment +
              tableBody?.wrist?.ulnar_deviation_impairment
          )
        }}
      </td>
    </tr>

    <!-- last line -->
    <tr style="text-align: right">
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
        colspan="5"
      >
        [1]
      </th>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
        colspan="2"
      >
        [2]
      </th>
    </tr>

    <tr>
      <th colspan="5">
        Add imp % Flex/Ext + RD/UD =
        {{
          wristTotalDigitalImpairment ? wristTotalDigitalImpairment + '%' : ''
        }}
      </th>

      <th colspan="2">Imp % = 0%</th>
    </tr>
    <!-- wrist only -->

    <!-- elbow only -->
    <tr>
      <td class="rotate" rowspan="8">
        <div>Elbow</div>
      </td>
      <td></td>
      <td>Flexion</td>
      <td>Extension</td>
      <td>Ankylosis</td>
      <td>Imp %</td>

      <td rowspan="6" colspan="2"></td>
      <td rowspan="8" colspan="2" valign="middle" align="center">
        {{ elbowRegionalImpairment }}
      </td>
    </tr>

    <tr>
      <td>Angle°</td>
      <td>{{ tableBody?.elbow?.flexion_degree ?? '' }}</td>
      <td>{{ tableBody?.elbow?.extension_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody?.elbow?.flexion_impairment ?? '' }}</td>
      <td>{{ tableBody?.elbow?.extension_impairment ?? '' }}</td>
      <td></td>

      <td>
        {{
          validImp(
            tableBody?.elbow?.flexion_impairment +
              tableBody?.elbow?.extension_impairment
          )
        }}
      </td>
    </tr>
    <tr>
      <td></td>
      <td>Pronation</td>
      <td>Supination</td>
      <td>Ankylosis</td>
      <td>Imp%</td>
    </tr>
    <tr>
      <td>Angle°</td>
      <td>{{ tableBody?.elbow?.pronation_degree }}</td>
      <td>{{ tableBody?.elbow?.supination_degree }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody?.elbow?.pronation_impairment }}</td>
      <td>{{ tableBody?.elbow?.supination_impairment }}</td>
      <td></td>

      <td>
        {{
          validImp(
            tableBody?.elbow?.pronation_impairment +
              tableBody?.elbow?.supination_impairment
          )
        }}
      </td>
    </tr>

    <!-- last line -->
    <tr style="text-align: right">
      <th
        style="
          text-align: right;
          border-bottom: 0.063rem solid white !important;
        "
        colspan="5"
      >
        [1]
      </th>
      <th
        style="
          text-align: right;
          border-bottom: 0.063rem solid white !important;
        "
        colspan="2"
      >
        [2]
      </th>
    </tr>

    <tr>
      <th colspan="5">
        Add imp % Flex/Ext + Pro/Sup =
        {{
          elbowTotalDigitalImpairment ? elbowTotalDigitalImpairment + '%' : ''
        }}
      </th>

      <th colspan="2">Imp % = 0%</th>
    </tr>
    <!-- elbow only -->

    <!-- shoulder only -->
    <tr>
      <td class="rotate" rowspan="11">
        <div>Shoulder</div>
      </td>
      <td></td>
      <td>Flexion</td>
      <td>Extension</td>
      <td>Ankylosis</td>
      <td>Imp %</td>

      <td rowspan="9" colspan="2">
        <ul>
          <li
            v-for="disorder in shoulderOtherDisorders.disorders"
            :key="disorder"
          >
            {{ disorder }}
          </li>
        </ul>
      </td>

      <td rowspan="11" colspan="2" valign="middle" align="center">
        {{ shoulderRegionalImpairment }}
      </td>
    </tr>

    <tr>
      <td>Angle°</td>
      <td>{{ tableBody?.shoulder?.flexion_degree ?? '' }}</td>
      <td>{{ tableBody?.shoulder?.extension_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody?.shoulder?.flexion_impairment ?? '' }}</td>
      <td>{{ tableBody?.shoulder?.extension_impairment ?? '' }}</td>
      <td></td>
      <td>
        {{
          validImp(
            tableBody?.shoulder?.flexion_impairment +
              tableBody?.shoulder?.extension_impairment
          )
        }}
      </td>
    </tr>
    <tr>
      <td></td>
      <td>Adduction</td>
      <td>Abduction</td>
      <td>Ankylosis</td>
      <td>Imp%</td>
    </tr>
    <tr>
      <td>Angle°</td>
      <td>{{ tableBody?.shoulder?.adduction_degree ?? '' }}</td>
      <td>{{ tableBody?.shoulder?.abduction_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody?.shoulder?.adduction_impairment ?? '' }}</td>
      <td>{{ tableBody?.shoulder?.abduction_impairment ?? '' }}</td>
      <td></td>
      <td>
        {{
          validImp(
            tableBody?.shoulder?.adduction_impairment +
              tableBody?.shoulder?.abduction_impairment
          )
        }}
      </td>
    </tr>
    <tr>
      <td></td>
      <td>Int Rot</td>
      <td>Ext Rot</td>
      <td>Ankylosis</td>
      <td>Imp%</td>
    </tr>
    <tr>
      <td>Angle°</td>
      <td>{{ tableBody?.shoulder?.internal_rotation_degree ?? '' }}</td>
      <td>{{ tableBody?.shoulder?.external_rotation_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody?.shoulder?.internal_rotation_impairment ?? '' }}</td>
      <td>{{ tableBody?.shoulder?.external_rotation_impairment ?? '' }}</td>
      <td></td>
      <td>
        {{
          validImp(
            tableBody?.shoulder?.external_rotation_impairment +
              tableBody?.shoulder?.internal_rotation_impairment
          )
        }}
      </td>
    </tr>

    <!-- last line -->
    <tr>
      <th
        style="
          text-align: right;
          border-bottom: 0.063rem solid white !important;
        "
        colspan="5"
      >
        [1]
      </th>
      <th
        style="
          text-align: right;
          border-bottom: 0.063rem solid white !important;
        "
        colspan="2"
      >
        [2]
      </th>
    </tr>

    <tr>
      <th colspan="5">
        Add imp % Flex/Ext + Add/Abd =
        {{
          shoulderTotalDigitalImpairment
            ? shoulderTotalDigitalImpairment + '%'
            : ''
        }}
      </th>

      <th colspan="2">Imp % = {{ shoulderOtherDisorders.total ?? 0 }}%</th>
      <th colspan="2">Imp % = ...</th>
    </tr>
    <!-- elbow only -->
  </tbody>
</template>

<script>
import { percentageCheck } from '@/utils/generics.js'
import { combineFormula } from '@/utils/calculations.js'

export default {
  name: 'UeierShoulderWristElbowTableBody',

  props: {
    tableBody: {
      type: Object,
      default: null,
    },
  },

  computed: {
    wristTotalDigitalImpairment() {
      if (this.tableBody.wrist) {
        return Math.round(
          this.tableBody.wrist.flexion_impairment +
            this.tableBody.wrist.extension_impairment +
            this.tableBody.wrist.radial_deviation_impairment +
            this.tableBody.wrist.ulnar_deviation_impairment
        )
      } else return null
    },

    wristRegionalImpairment() {
      if (this.wristTotalDigitalImpairment) {
        let bValue = this.tableBody?.wrist?.otherDisorders ?? 0
        return combineFormula(this.wristTotalDigitalImpairment, bValue) + '%'
      } else return ''
    },

    elbowTotalDigitalImpairment() {
      if (this.tableBody.elbow) {
        return Math.round(
          this.tableBody.elbow.flexion_impairment +
            this.tableBody.elbow.extension_impairment +
            this.tableBody.elbow.pronation_impairment +
            this.tableBody.elbow.supination_impairment
        )
      } else return null
    },

    elbowRegionalImpairment() {
      if (this.elbowTotalDigitalImpairment) {
        let bValue = this.tableBody?.elbow?.otherDisorders ?? 0
        return (
          Math.round(combineFormula(this.elbowTotalDigitalImpairment, bValue)) +
          '%'
        )
      } else return ''
    },

    shoulderTotalDigitalImpairment() {
      if (this.tableBody.shoulder) {
        return Math.round(
          this.tableBody.shoulder.flexion_impairment +
            this.tableBody.shoulder.extension_impairment +
            this.tableBody.shoulder.adduction_impairment +
            this.tableBody.shoulder.abduction_impairment +
            this.tableBody.shoulder.external_rotation_impairment +
            this.tableBody.shoulder.internal_rotation_impairment
        )
      } else return null
    },

    shoulderOtherDisorders() {
      let shoulder = { disorders: [], total: 0 }

      //Include excision impairment if present
      if (this.shoulderTotalDigitalImpairment) {
        shoulder.total += this.tableBody?.shoulder?.ac_joint_excision_impairment
        shoulder.disorders.push(
          'Distal Clavicle (Isolated) Arthroplasty, RTWSA IAG Para2.16, - 5%'
        )
      }
      return shoulder
    },

    shoulderRegionalImpairment() {
      return this.shoulderTotalDigitalImpairment
        ? Math.round(
            combineFormula(
              this.shoulderTotalDigitalImpairment,
              this.shoulderOtherDisorders.total
            )
          ) + '%'
        : ''
    },
  },

  methods: {
    validImp(value) {
      return percentageCheck(value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
