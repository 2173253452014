<template>
  <tbody class="table-white table-bordered border-dark">
    <!-- thumb only -->
    <tr>
      <td colspan="3"></td>
      <td>Flexion</td>
      <td>Extension</td>
      <td>Ankylosis</td>
      <td>Imp %</td>
      <td rowspan="12" valign="middle" align="center">
        <img
          class="ueier-img"
          src="@/assets/img/ueier/thumb_bone.png"
          alt="upper limbs image"
          style="width: auto; height: auto"
        />
      </td>
      <td rowspan="12" valign="middle" align="center">
        <img
          class="ueier-img"
          src="@/assets/img/ueier/thumb_finger.png"
          alt="upper limbs image"
          style="width: auto; height: auto"
        />
      </td>
      <td rowspan="12"></td>
      <td rowspan="6" colspan="2"></td>
    </tr>
    <tr>
      <td class="rotate" rowspan="13">
        <div>{{ tableBody?.body_part }}</div>
      </td>
      <td class="rotate" rowspan="2"><div>IP</div></td>
      <td>Angle°</td>
      <td>{{ tableBody.ip_flexion_degree ?? '' }}</td>
      <td>{{ tableBody.ip_extension_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.ip_flexion_impairment ?? '' }}</td>
      <td>{{ tableBody.ip_extension_impairment ?? '' }}</td>
      <td></td>
      <td>
        {{
          validImp(
            tableBody.ip_extension_impairment + tableBody.ip_flexion_impairment
          )
        }}
      </td>
    </tr>
    <tr>
      <td class="rotate" rowspan="2"><div>MP</div></td>
      <td>Angle°</td>
      <td>{{ tableBody.mp_flexion_degree ?? '' }}</td>
      <td>{{ tableBody.mp_extension_degree ?? '' }}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.mp_flexion_impairment ?? '' }}</td>
      <td>{{ tableBody.mp_extension_impairment ?? '' }}</td>
      <td></td>
      <td>
        {{
          validImp(
            tableBody.mp_extension_impairment + tableBody.mp_flexion_impairment
          )
        }}
      </td>
    </tr>
    <tr>
      <td colspan="3"></td>
      <td>Motion</td>
      <td>Ankylosis</td>
      <td>Imp%</td>
    </tr>

    <tr>
      <td class="rotate" rowspan="6"><div>CMC</div></td>
      <td rowspan="2">Radial abduction</td>
      <td>Angle°</td>
      <td>{{ tableBody.cmc_abduction_degree ?? '' }}</td>
      <td></td>
      <td></td>
      <td>Abnormal motion [1]</td>
      <td>{{ validImp(abnormalMotion) }}</td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.cmc_abduction_impairment ?? '' }}</td>
      <td></td>
      <td>{{ validImp(tableBody.cmc_abduction_impairment) }}</td>
      <td>Amputation [2]</td>
      <td></td>
    </tr>
    <tr>
      <td rowspan="2">Adduction</td>
      <td>Cm</td>
      <td>{{ tableBody.cmc_adduction_degree ?? '' }}</td>
      <td></td>
      <td></td>
      <td>Sensory loss [3]</td>
      <td></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.cmc_adduction_impairment ?? '' }}</td>
      <td></td>
      <td>{{ validImp(tableBody.cmc_adduction_impairment) }}</td>
      <td>Other disorder [4]</td>
      <td></td>
    </tr>
    <tr>
      <td rowspan="2">Opposition</td>
      <td>Cm</td>
      <td>{{ tableBody.cmc_opposition_degree ?? '' }}</td>
      <td></td>
      <td></td>
      <td rowspan="2">
        Total digit imp %
        <br />
        •Combine 1, 2, 3, 4
      </td>
      <td rowspan="2"></td>
    </tr>
    <tr>
      <td>Imp%</td>
      <td>{{ tableBody.cmc_opposition_impairment ?? '' }}</td>
      <td></td>
      <td>{{ validImp(tableBody.cmc_opposition_impairment) }}</td>
    </tr>
    <!-- last line -->
    <tr>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
        colspan="6"
      >
        [1]
      </th>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
      >
        [2]
      </th>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
      >
        [3]
      </th>
      <th
        style="
          text-align: right;
          border-collapse: collapse;
          border-bottom: 1px solid white !important;
        "
      >
        [4]
      </th>
      <th rowspan="2">
        Hand Impairment %
        <br />
        *Convert above
      </th>
      <th rowspan="2">
        {{ handImpairment !== null ? handImpairment + '%' : '' }}
      </th>
    </tr>
    <tr>
      <th colspan="6">
        <div>
          <span>
            Add digit impairment % CMC + MP + IP =
            {{ validImp(abnormalMotion) }}
          </span>
        </div>
      </th>

      <th>
        <div>
          <span>Digit IMP% = ...</span>
        </div>
      </th>
      <th>
        <div>
          <span>Digit IMP% = ...</span>
        </div>
      </th>
      <th>
        <div>
          <span>Digit IMP% = ...</span>
        </div>
      </th>
    </tr>
    <!-- thumb only -->
  </tbody>
</template>

<script>
import DegreeCheck from '@/mixins/DegreeCheckMixin'
import { percentageCheck } from '@/utils/generics.js'

export default {
  name: 'UeierThumbTableBody',
  mixins: [DegreeCheck],
  props: {
    tableBody: {
      type: Object,
      default: null,
    },
    handImpairment: {
      type: Number,
      default: null,
    },
    abnormalMotion: {
      type: [String, Number],
      default: null,
    },
  },

  computed: {},

  methods: {
    validImp(value) {
      return percentageCheck(value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
