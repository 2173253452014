<template>
  <div class="table-responsive">
    <table class="ueier-table table table-bordered border-dark">
      <thead class="align-top">
        <tr class="text-center">
          <th colspan="7">Abnormal Motion</th>
          <th scope="col">Amputation</th>
          <th scope="col">Sensory Loss</th>
          <th scope="col">Other Disorders</th>
          <th colspan="2">Hand Impairment %</th>
        </tr>
        <tr>
          <td colspan="7">
            Record motion or ankyloses angels and digit impairment %
          </td>
          <td scope="col">Mark level & impairment %</td>
          <td scope="col">Mark type, level, & impairment %</td>
          <td scope="col">Mark type, level, & impairment %</td>
          <td colspan="2">
            • Combine digit imp
            <br />
            %*Convert to hand imp %
          </td>
        </tr>
      </thead>

      <ThumbTableBody
        v-if="handTableBodyData?.thumb"
        :table-body="handTableBodyData?.thumb"
        :hand-impairment="totalHandImpairment.thumb?.hi"
        :abnormal-motion="totalHandImpairment.thumb?.di"
      />

      <tbody>
        <tr>
          <td colspan="3"></td>
          <td>Flexion</td>
          <td>Extension</td>
          <td>Ankylosis</td>
          <td>Imp %</td>

          <td colspan="4"></td>
        </tr>
      </tbody>

      <NonThumbTableBody
        v-if="handTableBodyData?.index_finger"
        :table-body="handTableBodyData?.index_finger"
        :hand-impairment="totalHandImpairment?.index_finger?.hi"
        :abnormal-motion="totalHandImpairment?.index_finger?.di"
      />
      <NonThumbTableBody
        v-if="handTableBodyData?.middle_finger"
        :table-body="handTableBodyData?.middle_finger"
        :hand-impairment="totalHandImpairment?.middle_finger?.hi"
        :abnormal-motion="totalHandImpairment?.middle_finger?.di"
      />
      <NonThumbTableBody
        v-if="handTableBodyData?.ring_finger"
        :table-body="handTableBodyData?.ring_finger"
        :hand-impairment="totalHandImpairment?.ring_finger?.hi"
        :abnormal-motion="totalHandImpairment?.ring_finger?.di"
      />
      <NonThumbTableBody
        v-if="handTableBodyData?.pinky_finger"
        :table-body="handTableBodyData?.pinky_finger"
        :hand-impairment="totalHandImpairment?.pinky_finger?.hi"
        :abnormal-motion="totalHandImpairment?.pinky_finger?.di"
      />
    </table>

    <!-- calculation footer -->
    <table class="ueier-table table table-bordered border-dark">
      <thead><tr></tr></thead>
      <tbody>
        <tr>
          <td scope="col">
            Total hand impairment: Add hand impairment % for thumb
            <u>&emsp;{{ totalHandImpairment.thumb?.hi }}&emsp;</u>
            % + index
            <u>&emsp;{{ totalHandImpairment?.index_finger?.hi }}&emsp;</u>
            % + middle
            <u>&emsp;{{ totalHandImpairment?.middle_finger?.hi }}&emsp;</u>
            % + ring finger
            <u>&emsp;{{ totalHandImpairment?.ring_finger?.hi }}&emsp;</u>
            % + little finger
            <u>&emsp;{{ totalHandImpairment?.pinky_finger?.hi }}&emsp;</u>
            %
          </td>
          <td colspan="3">{{ totalHandImpairment.total }}%</td>
        </tr>
        <tr>
          <td scope="col">
            Convert total hand impairment to upper extremity impairment† (If
            thumb metacarpal intact, enter on Part 2, line II) =
          </td>
          <td colspan="3">{{ totalUEI }}%</td>
        </tr>
        <tr>
          <td scope="col">
            ‡ Add thumb ray upper extremity amputation imp [5] ______% + hand
            upper extremity imp _______ % =
          </td>
          <td colspan="3">%</td>
        </tr>
        <tr>
          <td scope="col">
            If hand region impairment is only impairment, convert upper
            extremity impairment to whole person impairments =
          </td>
          <td colspan="3">{{ totalWPI }}%</td>
        </tr>
      </tbody>
    </table>

    <!-- legends -->
    <p class="ueier-citations">
      •Combined Values Chart (p. 604). &emsp; *Use Table 16-1 (digits to hand).
      &emsp; †Use Table 16-2 (hand to upper extremity). &emsp; ‘Use Table
      16-3.Courtesy of G. de Groot Swanson, MD. Grand Rapids, Michigan.
    </p>
  </div>
</template>

<script>
import ThumbTableBody from '@/components/Report/UEIER/ThumbTableBody.vue'
import NonThumbTableBody from '@/components/Report/UEIER/NonThumbTableBody.vue'
import { roundToHundred } from '@/utils/generics.js'
import { combineFormula } from '@/utils/calculations.js'

export default {
  name: 'UeierHandTable',
  components: {
    ThumbTableBody,
    NonThumbTableBody,
  },

  props: {
    tableData: {
      type: [Object],
      default: null,
    },
    direction: {
      type: String,
      default: null,
    },
  },

  data() {
    return {}
  },

  computed: {
    handTableBodyData() {
      let final = {
        thumb: {
          body_part: 'Thumb',
        },
        index_finger: {
          body_part: 'Index',
        },
        middle_finger: {
          body_part: 'Middle',
        },
        ring_finger: {
          body_part: 'Ring',
        },
        pinky_finger: {
          body_part: 'Little',
        },
      }

      if (this.tableData) {
        this.tableData.forEach((element) => {
          let obj = {}
          element.fields.forEach(({ degree, field_name, impairment_value }) => {
            //ensure no space in between key name
            obj[`${field_name.replace(/ /g, '_')}_degree`] = degree
            return (obj[`${field_name.replace(/ /g, '_')}_impairment`] =
              impairment_value)
          })
          return (final[element.name] = { ...final[element.name], ...obj })
        })

        return final
      } else return null
    },

    totalHandImpairment() {
      // thumb to HI = impairment * 0.4
      // non thumb to HI = impairment * 0.2 [ index , middle ]
      // non thumb to HI = impairment * 0.1 [ ring , pinky ]

      let final = {
        total: 0,
        thumb: null,

        index_finger: null,

        middle_finger: null,

        ring_finger: null,

        pinky_finger: null,
      }

      this.tableData?.forEach((finger) => {
        final[finger.name] = finger.calculations

        return (final.total =
          this.$store.state.extremityCalculations.hi[`total_${this.direction}`])
      })

      return final
    },

    totalUEI() {
      // UEI = upper extremity impairment

      if (this.totalHandImpairment.total) {
        return Math.round(this.totalHandImpairment.total * 0.9)
      } else return 0
    },

    totalWPI() {
      // WPI = whole person impairment

      if (this.totalUEI) {
        return Math.round((this.totalUEI * 60) / 100)
      } else return 0
    },
  },

  methods: {
    calculateFingerDI(ary) {
      let result = { dip_total: 0, pip_total: 0, mp_total: 0 }
      ary?.forEach((e) => {
        if (e.field_name.includes('dip')) {
          result.dip_total += e.impairment_value
        } else if (e.field_name.includes('pip')) {
          result.pip_total += e.impairment_value
        } else {
          return (result.mp_total += e.impairment_value)
        }
      })

      // round up all finger values
      Object.keys(result).forEach((e) => (result[e] = Math.round(result[e])))

      //combine 3 sub total values using the combine value calculator formula

      let FINAL = null
      // Loop through each input
      for (let subtotal in Object.values(result)) {
        // Get value of input
        const value = Math.round(Object.values(result)[subtotal])

        // Initialize for first value
        if (FINAL === null) {
          FINAL = value

          // Continue to second loop
          continue
        }

        //  Combine values via formula
        FINAL = combineFormula(FINAL, value)
      }
      // Return value parsed to float and 2 decimal places

      let output = Math.round(FINAL)

      return roundToHundred(output)
    },
  },
}
</script>

<style lang="scss" scoped>
.ueier {
  &-table {
    font-size: 12px;
    thead {
      td,
      th {
        padding: 8px;
      }
    }

    :deep(tbody) {
      border-top: 0 !important;

      tr {
        td,
        th {
          padding: 8px;
        }
        .rotate {
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          width: 1.5em;
        }
        .rotate div {
          transform: rotate(-90deg); /* Saf3.1+, Chrome */
          filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083); /* IE6,IE7 */
          -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)'; /* IE8 */
          margin-left: -10em;
          margin-right: -10em;
          font-weight: 600;
        }
      }

      .ueier-img {
        max-height: 250px;
      }
    }
  }
  &-citations {
    font-size: 12px;
  }
}
</style>
