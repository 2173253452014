<template>
  <!-- calculation footer -->
  <table class="ueier-table table table-bordered border-dark">
    <thead><tr></tr></thead>
    <tbody>
      <tr>
        <td scope="col">i- Amputation impairment (Other than digits) =</td>
        <td colspan="3">0%</td>
      </tr>
      <tr>
        <td scope="col">
          ii- Regional impairment of upper extremity•(Combine hand
          <u>&emsp;{{ totalRegionalImpairments.hand }}&emsp;</u>
          % + wrist
          <u>&emsp;{{ totalRegionalImpairments.wrist }}&emsp;</u>
          % + elbow
          <u>&emsp;{{ totalRegionalImpairments.elbow }}&emsp;</u>
          % + shoulder
          <u>&emsp;{{ totalRegionalImpairments.shoulder }}&emsp;</u>
          %) =
        </td>
        <td colspan="3">{{ totalRegionalImpairments.total }}%</td>
      </tr>
      <tr>
        <td scope="col">iii- Peripheral nerve system impairment =</td>
        <td colspan="3">0%</td>
      </tr>
      <tr>
        <td scope="col">iv- Peripheral vascular system impairment =</td>
        <td colspan="3">0%</td>
      </tr>
      <tr>
        <td scope="col">
          v- Other disorders (not included in regional impairment =
        </td>
        <td colspan="3">0%</td>
      </tr>
    </tbody>
  </table>

  <table class="ueier-table table table-bordered border-dark">
    <thead><tr></tr></thead>
    <tbody>
      <tr>
        <td scope="col">
          Total upper extremity impairment (•Combine I, II, III, IV, and V) = 0%
          + {{ totalRegionalImpairments.total }}% + 0% + 0% + 0%
        </td>
        <td colspan="3">{{ totalRegionalImpairments.total }}%</td>
      </tr>
      <tr>
        <td scope="col">Impairment of the whole person (Use Table 16-3) =</td>
        <td colspan="3">{{ totalWPI }}%</td>
      </tr>
    </tbody>
  </table>

  <!-- legends -->
  <p class="ueier-citations">
    •Combined Values Chart (p. 604).
    <br />
    If both limbs are involved, calculate the whole person impairment for each
    on a separate chart and combine the percents (Combined Valued Chart).
  </p>
</template>

<script>
export default {
  name: 'UeierCalculationFooter',

  props: {
    direction: {
      type: String,
      default: null,
    },
  },

  data() {
    return {}
  },

  computed: {
    totalRegionalImpairments() {
      let impairments = {
        hand: 0,
        shoulder: 0,
        wrist: 0,
        elbow: 0,
        total: 0,
      }

      // Get total hand impairment

      impairments.hand =
        this.$store.state.extremityCalculations.hi[`total_${this.direction}`]

      let uei = this.$store.state.extremityCalculations.uei[this.direction]

      // Get elbow,shoulder,wrist uei
      const NON_HAND_PARTS = ['shoulder', 'wrist', 'elbow']

      if (uei.length) {
        uei.forEach(({ body_part, value }) => {
          if (NON_HAND_PARTS.includes(body_part)) {
            impairments[body_part] = value
          }
        })
      }

      // Total all values
      impairments.total =
        this.$store.state.extremityCalculations.uei[`total_${this.direction}`]

      return impairments
    },

    totalWPI() {
      return this.$store.state.extremityCalculations.wpi[this.direction]
    },
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.ueier {
  &-table {
    font-size: 12px;

    tbody {
      border-top: 0 !important;

      tr {
        td,
        th {
          padding: 8px;
        }
      }

      .ueier-img {
        &-limb {
          max-height: 750px;
          // 250 x 3 ( elbow, wrist, shoulder )
        }
      }
    }
  }

  &-citations {
    font-size: 12px;
  }
}
</style>
